/* -----------------------------------------------------------------------------

KENBURNER RESPONSIVE BASIC STYLES OF HTML DOCUMENT

Screen Stylesheet

version:   	1.0
date:      	07/27/11
author:		themepunch
email:     	support@themepunch.com
website:   	http://www.themepunch.com
-----------------------------------------------------------------------------*/

.boxedcontainer {
  max-width: 1170px;
  margin: auto;
  padding: 0px 30px;
}

/*********************************************
	-	SETTINGS FOR BANNER CONTAINERS	-
**********************************************/

.tp-banner-container {
  width: 100%;
  position: relative;
  padding: 0;
}

.tp-banner {
  width: 100%;
  position: relative;
}

.tp-banner-fullscreen-container {
  width: 100%;
  position: relative;
  padding: 0;
}

.user_name {
  font-size: 12px !important;
  margin-left: 0px !important;
}
.user_name_me {
  font-size: 10px !important;
  margin-left: 5px !important;
}
