/*------------------------------------------------------------------
[Table of contents]

#index.html - HOME PAGE
1. General
2. Footer
3. Header
3.1. Avatar
4. Content
4.1. Pagination
4.2. Sidebar
4.3. Post

#02_topic.html - TOPIC
5. Breadcrumb
6. Topic
7. Quote post


#03_new_topic.html - NEW TOPIC

#04_new_account.html - NEW ACCOUNT

8. Extra small devices (less 767px)
8.1. index.html
8.2. 02_topic.html
8.3. 03_new_topic.html
9. Small devices (768 - 991px)
10. Medium devices (992-1200px)
11. Large devices (more 1200px)

-------------------------------------------------------------------*/

/*-----------------------------------------------------------------*/
/*--- General ---*/

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  font-family: "Poppins", sans-serif;
  background-color: #f5f5fa;
  font-size: 14px;
  color: #989c9e;
  overflow-x: hidden;
}

::placeholder {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.question-text {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #17171f;
}

.question-text:hover {
  color: #7842e8;
}

.more-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #17171f;
}

.more-text:hover {
  color: #7842e8;
}

.answer-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #17171f;
}

a {
  color: #989c9e;
}

.justify-between {
  justify-content: space-between;
}

.coming-soon-tag {
  font-size: 12px !important;
  background: #f5f5fa !important;
  padding: 0 3px !important;
  border-radius: 3px !important;
  color: #7842e8 !important;
}

a:hover {
  color: #0d6efd;
}

.postinfobot svg {
  display: inline;
}

/*--------- navbar--------- */
.navbar-nav-purple {
  background: #7842e8 !important;
  height: auto;
  border-radius: 0 !important;
}

.list-group-item {
  padding: 5px 5px !important;
}

.list-group {
  flex-direction: row;
  justify-content: space-around;
}

select:focus {
  box-shadow: none !important;
}

.bg-light-purple {
  background-color: #7842e81a !important;
}

.post-icon {
  position: absolute;
  top: 14px;
  right: 15px;
  font-size: 22px;
  color: #7842e8;
  cursor: pointer;
}

.bg-white {
  background-color: #fff;
  border-radius: 5px;
}

.radius-5 {
  border-radius: 5px;
}

.dark-color {
  color: #17171f;
}

.border-top-left-radius-0 {
  border-top-left-radius: 0 !important;
}

.border-top-right-radius-0 {
  border-top-right-radius: 0 !important;
}

.border-bottom-left-radius-0 {
  border-bottom-left-radius: 0 !important;
}

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0 !important;
}

.tree_label_3 {
  position: relative;
}

.down-line-1 {
  position: relative;
}

.down-line-1::after {
  position: absolute;
  bottom: 0;
  left: 1.1em;
  display: block;
  height: 80%;
  width: 0;
  border-left: 2px solid #efefef;
  content: "";
  top: 31px;
}

.down-line-2 {
  position: relative;
}

.down-line-2::after {
  position: absolute;
  bottom: 0;
  left: 1em;
  display: block;
  height: 60%;
  width: 0;
  border-left: 2px solid #efefef;
  content: "";
  top: 31px;
}

/* for Second level comment  */
.tree_label_2 {
  position: relative;
}

.tree_label_2::before {
  position: absolute;
  top: -1rem;
  left: 1.1em;
  display: block;
  height: 2.5em;
  width: 2.4em;
  border-bottom: 2px solid #efefef;
  border-left: 2px solid #efefef;
  border-radius: 0 0 0 1.5em;
  content: "";
}

.label_2_commment {
  position: relative;
}

.label_2_commment::before {
  position: absolute;
  top: -1rem;
  left: 1.1em;
  display: block;
  height: 2.5em;
  width: 2.4em;
  border-bottom: 2px solid #efefef;
  border-left: 2px solid #efefef;
  border-radius: 0 0 0 1.5em;
  content: "";
}

.tree_label_2::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1.1em;
  display: block;
  height: 100%;
  width: 0;
  border-left: 2px solid #efefef;
  content: "";
}

/* for third level comment  */
.tree_label_3_side {
  position: relative;
}

.tree_label_3_side::after {
  position: absolute;
  top: -5px;
  bottom: 0;
  left: 1.1em;
  display: block;
  height: 170%;
  width: 0;
  border-left: 2px solid #efefef;
  content: "";
}

.tree_label_3::before {
  position: absolute;
  top: -1rem;
  left: -2.5em;
  display: block;
  height: 2.4em;
  width: 2.4em;
  border-bottom: 2px solid #efefef;
  border-left: 2px solid #efefef;
  border-radius: 0 0 0 1.5em;
  content: "";
}

.tree_label_3::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2.5em;
  display: block;
  height: 100%;
  width: 0;
  border-left: 2px solid #efefef;
  content: "";
}

.tree_label_comment_3 {
  position: relative;
}

.tree_label_comment_3::before {
  position: absolute;
  top: -1rem;
  left: 0.9em;
  display: block;
  height: 2.5em;
  width: 2.4em;
  border-bottom: 2px solid #efefef;
  border-left: 2px solid #efefef;
  border-radius: 0 0 0 1.5em;
  content: "";
}

/*--------- contact--------- */
.j-center {
  justify-content: center;
}

.contact-form input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 48px;
}

.contact-form input:focus {
  outline: none;
  box-shadow: none;
  border-color: #7842e8;
}

.contact-form textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #7842e8;
}

/*--------- login--------- */
.post-card input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 48px;
  background: #fff;
}

.post-card input:focus {
  box-shadow: none;
  border-color: #7842e8;
}

.login-screen {
  background-image: url("../../Assets/images/bg-login.jpg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
}

.account-screen {
  background-image: url("../../Assets/images/account.png");
  min-height: 100%;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-card h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #7842e8;
  text-align: center;
}

.login-card h5 {
  color: #17171f;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid #efefef !important;
  text-align: center;
  padding-bottom: 5px;
}

.login-card label {
  color: #17171f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.bg-purple {
  background-color: #7842e8;
}

.profile-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-purple {
  color: #7842e8 !important;
}

.page-link {
  border-radius: 5px;
  background: rgba(52, 47, 47, 0.1);
  border: none !important;
  padding: 2px 7px !important;
  color: #7842e8;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

.page-item {
  box-shadow: none;
  margin: 0 3px;
}

.page-item:focus {
  box-shadow: none;
}

.active>.page-link {
  background: #7842e8;
  color: #ffffff;
  box-shadow: none !important;
  padding: 2px 9px !important;
}

.action-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-ul ul {
  margin: 0 !important;
  padding-left: 0 !important;
  padding-top: 7%;
}

.login-screen p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.login-card .align-right {
  display: flex;
  justify-content: right;
}

.post-card {
  padding: 6% 4%;
  margin: 1%;
  background-color: #ffffff;
  border-radius: 30px;
}

.center-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.github-icon {
  color: #000000b3;
  cursor: pointer;
  font-size: 40px;
  margin: 1px 10px;
}

.facebook-icon {
  color: #3b5998;
  cursor: pointer;
  font-size: 40px;
  margin: 1px 10px;
}

.twitte-icon {
  color: #5ea9dd;
  cursor: pointer;
  font-size: 40px;
  margin: 1px 10px;
}

.login-card button {
  font-weight: 600;
  background-color: #7842e8;
}

.login-card button:hover {
  background-color: #6f3dd3;
}

.comment-area {
  background: #7842e81a !important;
  border: none !important;
  width: 100%;
}

.comment-area:focus {
  border: none;
  box-shadow: none !important;
}

/* profile edit  */
.upload-pic {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;

}

.edit-profiles {
  width: 100% !important;
  height: auto;
  margin-top: 35px;
  margin-bottom: 35px;

}



.upload-pic svg {
  display: inline;
}

.edit-profile label {
  color: rgba(23, 23, 31, 0.75);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.edit-profile input,
select {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 48px;
  background: #fff;
}

.edit-profile select:focus {
  border: 1px solid #7842e8 !important;
}

.edit-profile input:focus {
  box-shadow: none;
  border-color: #7842e8;
}

.create-topic input,
select {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 48px;
  background: #fff;
}

.create-topic select:focus {
  border: 1px solid #7842e8 !important;
}

.create-topic input:focus {
  box-shadow: none;
  border-color: #7842e8;
}

.update-div {
  display: flex;

  justify-content: right;
}

.btn-update {
  border-radius: 5px;
  background-color: #7842e8;
}

.btn-update:hover {
  border-radius: 5px;
  background-color: #7842e8;
}

/*--------- sidebarbar--------- */
.sidebarLeft {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.sidebarLeft li {
  border: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5px 0;
}

.list-group-item {
  padding: 4px 4px !important;
}

.sidebarLeft li svg {
  font-size: 23px;
  color: #7842e8;
}

.sidebarLeft li span {
  margin-left: 0;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #17171f;
}

.color-white {
  color: #fff;
}

.sidebarLeft a {
  text-decoration: none;
  margin: 1px 0;
}

.sidebarLeft a:hover li {
  background-color: #7842e8;
}

.sidebarLeft a:hover li svg {
  color: #fff;
}

.sidebarLeft .active {
  background-color: #7842e8;
  color: #ffffff !important;
  border-radius: 10px;
}

.sidebarLeft .active li {
  background-color: #7842e8;
  color: #ffffff !important;
}

.sidebarLeft .active li span {
  color: #ffffff;
}

.sidebarLeft .active li svg {
  color: #ffffff;
}

.sidebarLeft a:hover li span {
  color: #ffffff;
}

.sidebarLeft a:hover {
  background-color: #7842e8;
  color: #ffffff !important;
  border-radius: 10px;
}

/* Right  */
.sidebarRight {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  height: 100%;
}

.sidebarRight li {
  border: 0;
  display: flex;
  align-items: center;
  margin: 5px 5px 0 5px;
}

.sidebarRight a {
  text-decoration: none;
}

.sidebarRight li span {
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #17171f;
}

.sidebar-right-span {
  font-weight: 500 !important;

  font-size: 12px !important;

  line-height: 18px !important;
}

.trending-head {
  color: #17171f;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid #efefef !important;

  padding-top: 5px;
  padding-left: 5px;
}

.sidebarLeft b {
  color: #7842e8;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.answer-no {
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
}

.answer-no span {
  font-size: 8px !important;
  line-height: 12px !important;
  font-weight: 400 !important;
}

.answer-item {
  justify-content: space-between;
}

.answer-item-left {
  height: 25px !important;
  width: 25px !important;
  border-radius: 25px;
}

.answer-item-right {
  height: 30px !important;
  width: 30px !important;
}

/*------- categories card --------*/
.categories-card {
  background-color: #ffffff;
}

.categories-card h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.categories-card h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.categories-card p {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #17171fbf !important;
}

.categories-card a {
  text-decoration: none;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #7842e8;
  border-radius: 5px;
}

.categories-card .btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #7842e8;
  border-radius: 5px;
  width: 100%;
}

.categories-card .card {
  border-color: #efefef !important;
}

/* ------ profile page sidebar ---- */
.profile-card {
  border: 1px solid #efefef !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.con-card {
  border: 1px solid #efefef !important;
  border-radius: 10px;
}

.profile-card img {
  width: 70px;
  height: 70px;
  border-radius: 90px;
}

.con-card p {
  margin: 0 !important;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.con-card b {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #17171fbf !important;
}

.btn-outline {
  border: 1px solid #00000040 !important;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  background-color: transparent !important;
  padding: 6px 10px !important;
  color: #17171f !important;
}

h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  color: #17171f !important;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #17171f !important;
}

.profile-card p {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
}

.description-para {
  font-size: 12px;
  font-weight: 200;
  margin-bottom: 0;
}

.description-para p {
  font-size: 12px;
  font-weight: 200;
  margin-bottom: 0;
  display: inline-block;
}

.report-area {
  width: 100%;
  border: 1px solid #7842e8;
  border-radius: 8px;
}

.report-area:focus {
  outline: none !important;
  border: 1px solid #7842e8 !important;
}

.read-more {
  font-size: 12px;
  color: #1877f2;
  text-decoration: none;
}

.questions-card img {
  width: 35px;
  height: 31px;
  border-radius: 35px;
}

.questions-card {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 3px 0 0 0;
  border-radius: 5px;
}

.questions-card span {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 5px;
  padding-bottom: 7px;
  /* border-bottom: 1px solid #efefef !important; */
  color: #17171f;
}

.icon-div {
  margin-top: 15px;
  display: flex;
}

.icon-div svg {
  color: #7842e8;
  cursor: pointer;
}

.icon-div span {
  border-bottom: none !important;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 0.5) !important;
}

.light-color {
  color: rgba(0, 0, 0, 0.5) !important;
}

.heading-border {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 16px 10px;
  margin-top: 5px;
  background-color: #fff;
}

.following-card {
  background-color: #fff;
  margin: 3px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.following-card img {
  width: 46px;
  height: 46px;
  border-radius: 46px;
}

.following-card span:first-child {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #17171f;
}

.following-card-info b {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
}

.following-card-info span {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 0.5) !important;
}

.following-card-i {
  color: #7842e8;
  font-size: 30px;
}

.top-margin-padding {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.padding-left ul {
  padding: 1% 20px !important;
}

.sidebar-space div {
  padding: 0 !important;
}

/*-----------------------------------------------------------------*/
/*--- Footer ---*/

footer {
  height: 69px;
  line-height: 69px;
  color: #363838;
}

footer .logo {
  line-height: normal;
}

ul.socialicons li {
  display: inline-block;
  list-style: none;
  font-size: 18px;
  color: #bdc3c7;
  margin-left: 15px;
}

ul.socialicons li a {
  color: #bdc3c7;
}

ul.socialicons li a:hover {
  color: #ffffff;
}

.justify {
  justify-content: center;
}

.justify-md-start {
  justify-content: center;
  text-align: center;
}

.pagination {
  display: flex;
  margin-top: 3%;
}

.toggle-btn {
  background: transparent;
}

.toggle-btn:hover {
  background: transparent;
}

/*-----------------------------------------------------------------*/
/*--- Header ---*/
.headernav {
  height: 70px;
  border-bottom: 1px solid #c9cccd;
}

.content {
  background-color: #ecf0f1;
  border-top: solid 1px #e0e4e5;
}

.selecttopic {
  line-height: 69px;
  font-family: "Open Sans Semibold", sans-serif;
  font-size: 16px;
  color: #363838;
}

.selecttopic select {
  border: none;
}

.selecttopic .dropdown-menu {
  margin-top: -20px;
}

.headernav .search {
  color: #363838;
  font-size: 14px;
  font-family: "Open Sans Light", sans-serif;
  margin-top: 16px;
  height: 38px;
}

.headernav .search .wrap {
  background-color: #f3f5f9;
  border-radius: 3px;
}

.headernav .search .txt {
  width: 85%;
}

.headernav .search input {
  border: none;
  box-shadow: none;
  background-color: #f3f5f9;
  color: #363838;
  font-size: 14px;
  padding: 8px 19px;
  height: 38px;
}

.headernav .search button {
  border: solid 1px #697683;
  box-shadow: none;
  background-color: #697683;
  color: #ffffff;
  font-size: 18px;
}

aside div {
  padding: 0;
}

/*--- Avatar ---*/
.avt {
  margin-top: 16px;
  height: 38px;
}

.avt button {
  height: 38px;
  border: none;
  box-shadow: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans Bold", sans-serif;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #1abc9c;
}

.avt .btn-primary:hover,
.avt .btn-primary:focus,
.avt .btn-primary:active,
.avt .btn-primary.active {
  background-color: #1abc9c;

  border: none;
  box-shadow: none;
}

.env {
  font-size: 18px;
  color: #cfd5d7;
  line-height: 38px;
  padding: 0 20px;
}

.avatar {
  position: relative;
}

.avatar img {
  border-radius: 50%;
}

.avatar .status {
  position: absolute;
  right: 0;
  top: 0;
  width: 12px;
  height: 12px;
  line-height: 12px;
  border-radius: 50%;
  border: solid 2px #ffffff;
}

.dropdown.avatar .status {
  right: 14px;
}

.caretl {
  color: #363838;
  font-size: 14px;
  line-height: 38px;
  padding: 0 15px;
}

.avatar .green {
  background-color: #80d3ab;
}

.avatar .red {
  background-color: #f27777;
}

.avatar .yellow {
  background-color: #ecd346;
}

/*-----------------------------------------------------------------*/
/*--- Content ---*/

/*--- Pagination ---*/
.paginationforum li {
  list-style: none;
  display: inline-block;
  margin-left: 20px;
}

.paginationforum li a {
  min-width: 24px;
  height: 24px;
  font-size: 14px;
  font-family: "Open Sans Semibold", sans-serif;
  color: #ffffff;
  background-color: #cfd5d7;
  border-radius: 2px;
  display: block;
  padding: 0 8px;
  line-height: 24px;
}

.paginationforum li a.active,
.paginationforum li a:hover,
.paginationforum li a:focus,
.paginationforum li a:active {
  color: #363838;
  background-color: #ffffff;
  box-shadow: 0 1px 2px #c9cccd;
  font-family: "Open Sans Bold", sans-serif;
  text-decoration: none;
}

.paginationforum {
  margin: 19px auto;
  padding: 0;
}

.prevnext {
  font-size: 26px;
  color: #cfd5d7;
  margin-top: 11px;
  display: flex;
}

.prevnext.last {
  margin-left: 20px;
}

/*--- Sidebar ---*/

.sidebarblock {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px #c9cccd;
  margin-bottom: 20px;
}

.sidebarblock h3 {
  color: #363838;
  font-size: 14px;
  font-family: "Open Sans Bold", sans-serif;
  margin: 0;
  padding: 20px;
}

.sidebarblock .divline {
  height: 1px;
  line-height: 1px;
  border-bottom: solid 1px #f1f1f1;
}

.sidebarblock .blocktxt {
  padding: 20px;
}

ul.cats li {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  line-height: 30px;
}

ul.cats {
  margin: 0;
  padding: 0;
}

ul.cats li a {
  font-size: 14px;
  color: #363838;
  font-family: "Open Sans Light", sans-serif;
  line-height: 30px;
}

ul.cats .badge {
  background-color: #bdc3c7;
  font-size: 12px;
  color: #ffffff;
  font-family: "Open Sans Bold", sans-serif;
  margin-top: 7px;
}

.sidebarblock .blocktxt {
  font-size: 14px;
  color: #363838;
  font-family: "Open Sans Light", sans-serif;
}

.sidebarblock .blocktxt .smal {
  font-size: 12px;
}

.chbox {
  width: 50px;
}

table.poll {
  width: 100%;
}

.progress-bar.color1 {
  background-color: #9b59b6;
}

.progress-bar.color2 {
  background-color: #3498db;
}

.progress-bar.color3 {
  background-color: #e67e22;
}

.progress-bar {
  font-size: 14px;
  color: #ffffff;
  font-family: "Open Sans Bold", sans-serif;
  line-height: 31px;
  text-align: left;
  padding-left: 10px;
  box-shadow: none;
}

.progress {
  background-color: #ecf0f1;
  height: 31px;
  border-radius: 2px;
  box-shadow: none;
}

.poll label {
  margin-bottom: 0;
  margin-left: 20px;
}

.poll input[type="radio"] {
  display: none;
}

.poll input[type="radio"]+label {
  display: inline-block;
  width: 31px;
  height: 31px;
  background: url(../../Assets/images/radio.jpg) 0 0 no-repeat;
  vertical-align: middle;
  cursor: pointer;
}

.poll input[type="radio"]:checked+label {
  background: url(../../Assets/images/radio.jpg) -31px 0 no-repeat;
}

td.chbox {
  vertical-align: top;
}

/*--- Post --*/
.post {
  background-color: #ffffff;
  border: 1px solid #0d6efd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px #c9cccd;
  margin-bottom: 20px;
}

.post .wrap-ut {
  width: 85%;
}

.post .userinfo {
  width: 15%;
  padding: 20px 0 15px 15px;
}

.post .posttext {
  width: 85%;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;

  color: #989c9e;
  font-size: 14px;
  font-family: "Open Sans Light", sans-serif;
  line-height: 25px;
}

.post .postinfo {
  width: 15%;
  border-left: solid 1px #f1f1f1;
}

.post .avatar {
  width: 37px;
  margin-left: 5px;
}

.post .icons {
  width: 48px;
  border-top: solid 1px #f1f1f1;
  margin-top: 12px;
  padding-top: 7px;
}

.post h2 {
  color: #363838;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}

.post .comments {
  border-bottom: solid 1px #f1f1f1;
  padding: 18px 0 25px 0;
  text-align: center;
}

.post .comments .commentbg {
  background-color: #bdc3c7;
  border-radius: 2px;
  display: inline-block;
  padding: 12px 17px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans Bold", sans-serif;
  position: relative;
}

.post .comments .commentbg .mark {
  width: 11px;
  height: 11px;
  background-color: #bdc3c7;
  position: absolute;
  bottom: 0;
  left: 43%;
  margin-bottom: -5px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Opera, Chrome, and Safari */
}

.post .views {
  border-bottom: solid 1px #f1f1f1;
  color: #9da6aa;
  font-size: 12px;
  font-family: "Open Sans Regular", sans-serif;
  text-align: center;
  line-height: 29px;
}

.post .views i {
  font-size: 14px;
}

.post .time {
  color: #9da6aa;
  font-size: 12px;
  font-family: "Open Sans Regular", sans-serif;
  text-align: center;
  line-height: 29px;
}

.post .time i {
  font-size: 14px;
}

/*-----------------------------------------------------------------*/
/* #02_topic.html - TOPIC */

/*--- Breadcrumb ---*/
.breadcrumbf a,
.breadcrumbf {
  color: #bdc3c7;
  font-size: 12px;
  font-family: "Open Sans Regular", sans-serif;
  line-height: 62px;
}

.breadcrumbf a:hover {
  text-decoration: underline;
}

/*--- Topic ---*/
.topic .userinfo {
  width: 12%;
}

.topic .posttext {
  width: 88%;
}

.postinfobot {
  border-top: solid 1px #0d6efd;
  line-height: 50px;
  padding: 0 40px 0 50px;
}

/* .postinfobot .likeblock {
    width: 120px;
} */

.postinfobot .prev {
  width: 30px;
}

.postinfobot .prev a {
  font-size: 18px;
  color: #bdc3c7;
}

.postinfobot .posted {
  width: 300px;
  margin-left: 50px;
  font-size: 12px;
  color: #bdc3c7;
  font-family: "Open Sans Regular", sans-serif;
}

.postinfobot .posted i {
  font-size: 18px;
  color: #bdc3c7;
  padding-right: 8px;
}

.postinfobot .next {
  width: 90px;
  text-align: right;
}

.postinfobot .next a {
  font-size: 18px;
  color: #bdc3c7;
}

.postinfobot .next a i {
  padding-right: 18px;
}

.up {
  color: #1abc9c;
  font-size: 12px;
}

.up i,
.down i {
  font-size: 20px;
  padding-right: 10px;
}

.down {
  color: #db7a7a;
  font-size: 12px;
  margin-left: 20px;
}

a.up:hover {
  text-decoration: none;
  color: #1de4bd;
}

a.down:hover {
  text-decoration: none;
  color: #f48989;
}

.beforepagination {
  margin-bottom: 0;
}

/*--- Quote post ---*/
.post blockquote {
  border: solid 1px #f1f1f1;
  border-radius: 2px;
  font-size: 14px;
  padding: 18px;
}

.post blockquote .original,
.postreply {
  font-size: 12px;
  color: #bdc3c7;
  display: block;
  font-family: "Open Sans Regular", sans-serif;
}

.textwraper textarea {
  border: none;
  box-shadow: none;
  background-color: #e6f0ff;
  /*    width     : 610px;*/
  width: 100%;
}

.textwraper {
  background-color: #e6f0ff;
  border-radius: 2px;
  padding: 18px;
}

.notechbox {
  width: 20px;
}

.notechbox input {
  width: 12px;
  box-shadow: none;
  margin-top: 8px;
  border-color: #bdc3c7;
}

.notechbox input:active,
.notechbox input:focus,
.notechbox input:hover {
  box-shadow: none;
}

.postinfobot label {
  color: #bdc3c7;
  font-size: 12px;
  font-family: "Open Sans Regular", sans-serif;
}

.smile a {
  margin-right: 20px;
  font-size: 20px;
  color: #bdc3c7;
}

.btn-primary {
  background-color: #0d6efd;
  box-shadow: none;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans Bold", sans-serif;
  border: none;
}

.purple-btn {
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #7842e8;
}

.purple-btn:hover {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #7842e8;
}

.outline-btn {
  color: #7842e8;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none !important;
  border-radius: 10px;
  background: #7842e81a !important;
}

.outline-btn:hover {
  color: #7842e8;
}

.drop-name {
  margin-left: 9px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.profile-drop ul {
  padding: 0 !important;
}

.profile-drop ul li {
  text-align: center;
}

.profile-drop ul li a {
  text-decoration: none;
  color: #17171f;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.profile-drop ul li a:hover {
  color: #989c9e;
}

.focus\:ring-2:focus,
.focus\:ring-4:focus {
  box-shadow: none !important;
}

.profile-drop ul li svg {
  color: #7842e8;
  font-size: 20px;
}

.circular-img {
  width: 30px;
  height: 30px;
  top: 8px;
  left: 13px;
  margin-top: 2px;
  border-radius: 36px;
  display: inline-block;
}

/* create topic  */
.ql-container.ql-snow {
  border: none !important;
  background: #f5f5fa;
  margin-top: 10px;
  min-height: 173px;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  background: #f5f5fa;
  padding: 8px;
}

.nav-ul .nav-a {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  text-decoration: none;
  border: none;
  text-decoration: none;
  margin: 10px 0;
}

.nav-ul .nav-a:hover {
  color: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* border-bottom: 3px solid #fff; */
  text-decoration: none;
  background-color: transparent !important;
}

.pt-52 {
  padding-top: 13px;
}

.nav-ul .nav-a .active {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 3px solid #fff;
  text-decoration: none;
}

.newtopic input[type="text"],
.newtopic input[type="password"] {
  border-radius: 2px;
  box-shadow: none;
  border: none;
  border: 1px solid #989c9e;
  /* background-color: #f1f1f1; */
  padding: 15px;
  font-size: 14px;
  color: #989c9e;
  font-family: "Open Sans Light", sans-serif;
  margin-bottom: 20px;
  height: 50px;
}

.newtopic select {
  border-radius: 2px;
  box-shadow: none;
  /* border          : solid 1px #cfd5d7; */
  /* background-color: #ffffff; */
  border: 1px solid #989c9e;

  font-size: 14px;
  color: #989c9e;
  font-family: "Open Sans Light", sans-serif;
  margin-bottom: 20px;
  height: 50px;
}

.newtopic select:hover,
.newtopic select:focus,
.newtopic select:active {
  box-shadow: none;
}

.newtopic textarea {
  border-radius: 2px;
  box-shadow: none;
  border: none;
  /* background-color: #f1f1f1; */
  border: 1px solid #989c9e;
  padding: 15px;
  font-size: 14px;
  color: #989c9e;
  font-family: "Open Sans Light", sans-serif;
  margin-bottom: 20px;
  height: 150px;
}

.newtopic textarea:hover,
.newtopic textarea:focus,
.newtopic textarea:active {
  box-shadow: none;
}

.newtopic p {
  font-size: 14px;
  color: #363838;
  font-family: "Open Sans Light", sans-serif;
}

.newtopcheckbox input {
  margin-top: 5px;
  width: 18px;
  height: 18px;

  /*    width: 18px;
        box-shadow: none;
        margin-top: 8px;
        border-color: #bdc3c7;*/
}

.checkbox input {
  margin-right: 7px;
}

.checkbox .fa-facebook-square {
  color: #3b5b99;
  font-size: 20px;
  margin-top: 4px;
}

.checkbox .fa-google-plus-square {
  color: #e74724;
  font-size: 20px;
  margin-top: 4px;
}

.checkbox .fa-twitter {
  color: #42c8f4;
  font-size: 20px;
  margin-top: 4px;
}

.loading {
  text-align: right;
}

.similarposts {
  /*    height: 57px;*/
  margin-bottom: 20px;
}

.similarposts p {
  display: inline;
  color: #bdc3c7;
}

.similarposts p span {
  color: #363838;
}

.similarposts .fa-info-circle {
  font-size: 18px;
  color: #3498db;
  margin-right: 10px;
}

.similarposts .fa-spinner {
  font-size: 18px;
  color: #363838;
}

.text-35 {
  font-size: 20px;
  margin: 7px 4px;
  cursor: pointer;
}

.text-gray-500 {
  color: #fff !important;
}

.bg-gray-100:hover {
  background-color: transparent !important;
  color: #fff;
}

.postinfotop h2 {
  color: #363838;
  font-size: 14px;
  font-family: "Open Sans Bold", sans-serif;
  padding: 20px;
  margin: 0;
  border-bottom: solid 1px #f1f1f1;
}

.commentCount {
  font-size: 14px;
  margin-left: 8px;
}

/*-----------------------------------------------------------------*/
/*--- #04_new_account.html - NEW ACCOUNT ---*/

.acccap h3 {
  padding: 7px;
  margin: 0;
  border-radius: 2px;
  background-color: #697683;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-family: "Open Sans Regular", sans-serif;
}

.acccap .posttext {
  padding-bottom: 0;
}

.acccap .userinfo {
  padding-top: 0;
  padding-bottom: 0;
}

.accsection.privacy h3 {
  background-color: #9b59b6;
}

.accsection.privacy .posttext {
  padding: 0;
  padding-top: 20px;
}

.accsection.privacy p {
  margin-bottom: 0;
}

.accsection.privacy .checkbox {
  margin: 20px 0 10px 0;
}

.accsection.survey h3 {
  background-color: #e67e22;
}

.accsection.networks h3 {
  background-color: #80d3ab;
}

.accsection .htext {
  width: 40%;
  float: left;
}

.accsection .hnotice {
  width: 60%;
  float: left;
  text-align: right;
}

.networks .btn {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans Light", sans-serif;
  text-align: left;
}

.networks .btn-fb {
  background-color: #3b5b99;
}

.networks .btn-tw {
  background-color: #42c8f4;
}

.networks .btn-gp {
  background-color: #cd3d1e;
}

.networks .btn-pin {
  background-color: #bd1425;
}

.newaccountpage .postinfobot {
  padding: 0 30px 0 115px;
}

.nav-dropdown li a {
  text-decoration: none;

}

.nav-dropdown li a:hover {
  color: #989c9e
}

.accsection .imgsize {
  color: #989c9e;
  font-size: 12px;
  font-family: "Open Sans Regular", sans-serif;
  margin: 10px 0;
}

.accsection input[type="password"] {
  margin-bottom: 0;
}

/*-----------------------------------------------------------------*/
/*--- Extra small devices (less 767px) ---*/

@media (max-width: 767px) {

  /*--- index.html ---*/
  .container-fluid {
    padding: 0;
  }

  .post .userinfo {
    width: 30%;
    padding: 15px 0 15px 15px;
  }

  .post .posttext {
    width: 70%;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .post .comments .commentbg {
    font-size: 12px;
    padding: 7px;
    min-width: 35px;
  }

  .post .time i {
    display: block;
    margin-top: 5px;
  }

  .post .views i {
    display: block;
    margin-top: 5px;
  }

  .post .comments .commentbg .mark {
    left: 36%;
  }

  /* .post .time,
    .post .views,
    .post .views {} */

  .headernav {
    height: auto;
  }

  .avt {
    height: auto;
    margin-bottom: 15px;
  }

  .selecttopic select {
    width: 100%;
  }

  .avatar .dropdown-menu {
    left: auto;
    right: 0;
  }

  .selecttopic .dropdown-menu {
    margin-top: -20px;
  }

  .dropdown.avatar .status {
    right: 14px;
  }

  .dropdown.avatar b.caret {
    color: #363838;
  }

  .sociconcent {
    text-align: center;
  }

  /*--- 02_topic.html ---*/

  .postinfobot {
    padding: 10px;
  }

  .postinfobot .likeblock {
    width: 100%;
  }

  .postinfobot .posted {
    width: 200px;
    margin: 0;
  }

  .postinfobot .next {
    width: 76px;
    margin: 0;
  }

  .textwraper textarea {
    width: 175px;
  }

  footer ul.socialicons {
    margin: 0;
    padding: 0;
  }

  footer ul li a :hover {
    color: white;
  }

  /* end 2*/

  /*--- 03_new_topic.html ---*/
  #pass {
    margin-bottom: 20px;
  }

  .accsection .htext,
  .accsection .hnotice {
    width: 100%;
  }

  .accsection .hnotice {
    font-size: 10px;
  }

  .postinfobot .lblfch {
    width: 80%;
  }

  .postinfobot .lblfch label {
    line-height: normal;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  /* end 3 */
}

/*-----------------------------------------------------------------*/
/*--- Small devices (768 - 991px) ---*/

@media (min-width: 768px) and (max-width: 991px) {
  .nav-ul li {
    margin-left: 7px !important;
    margin-top: 3px;
  }

  .navbar-nav-purple>div {
    flex-wrap: nowrap !important;
  }

  .circular-img {
    width: 36px;
    height: 30px;
    top: 8px;
    left: 13px;
    margin-top: 2px;
    border-radius: 36px;
    display: inline-block;
  }

  .pt-52 {
    padding-top: 0px;
  }

  .nav-ul ul {
    margin: 0 !important;
    padding-left: 0 !important;
    padding-top: 0;
  }
}

/*-----------------------------------------------------------------*/
/*--- Medium devices (992-1200px) ---*/
@media (min-width: 992px) {
  .navbar-nav-purple {
    background: #7842e8 !important;
    height: 65px;
    padding-bottom: 15px;
    position: fixed;
    width: 100%;
    z-index: 9;
    border-radius: 0 !important;
  }

  .container-fluid {
    padding: 0;
  }

  .headernav .search .txt {
    width: 75%;
  }

  .avatar .dropdown-menu {
    left: auto;
    right: 0;
  }

  ul.socialicons {
    margin-bottom: 0;
  }

  .sidebarRight {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 100%;
    width: 20%;
    position: fixed;
    right: 4%;
  }

  /* 2 */
  .textwraper textarea {
    width: 100%;
  }

  .justify {
    justify-content: end;
  }

  .justify-md-start {
    justify-content: start;
    text-align: left;
  }

  /* end 2 */
  .profile-card img {
    width: 90px;
    height: 90px;
    border-radius: 90px;
  }

  .list-group {
    flex-direction: column !important;
  }

  .sidebarLeft {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    position: fixed;
    width: 20%;
    bottom: 0;
    left: 4%;
    z-index: 9;
    top: 65px;
    height: 100%;
    /* padding-top: 7%; */
  }

  .w-res-150 input {
    width: 400px !important;
  }

  .sidebarLeft li {
    /* height: 45px; */
    flex-direction: row;
    margin: 3px 5px 3px 5px;
  }

  .list-group-item {
    padding: 5px 5px !important;
  }

  .sidebarLeft li span {
    margin-left: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #17171f;
  }

  .pt-52 {
    padding-top: 65px;
  }

  .nav-ul ul {
    margin: 0 !important;
    padding-left: 0 !important;
    padding-top: 0;
  }
}

/*-----------------------------------------------------------------*/
/*--- Large devices (more 1200px) ---*/
@media (min-width: 1201px) {
  .list-group {
    flex-direction: column !important;
  }

  .container-fluid {
    padding: 0;
  }

  .profile-card img {
    width: 90px;
    height: 90px;
    border-radius: 90px;
  }

  .pt-52 {
    padding-top: 65px;
  }
}

@media (max-width: 1201px) {
  .w-res-150 input {
    width: 100% !important;
  }
}